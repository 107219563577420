/* eslint-disable camelcase */
import { updateDoc } from 'firebase/firestore';
import { UseFormReturn } from 'react-hook-form';
import { getTransactionRef } from 'src/utils/firebase';
import { extractBidValue, formatBidValue } from 'src/utils/formatBid';
import { Transaction } from 'src/types';
import { ITransactionOverview, ITransactionSummary, ITransactionSummaryUser } from 'src/redux/types';
import { transactionRoles, transactionUserRelationship } from 'src/pages/transactions/constants';
import { TransactionRole } from 'src/pages/transactions/types';
import { propertyDocumentAvailabilityCodes, propertyTitleDocumentsCategoriesKeys } from './constants';
import { FormTitleDocumentCategory, FormTitleDocumentItem } from './components/AvailableDocumentsModal/types';

export const formatUsers = (users: ITransactionSummaryUser[]) => (
	<ul>
		{users.map((u) => (
			<li key={u.id}>
				<b style={{ display: 'inline' }}>
					{u.givenNames} {u.lastNames}
				</b>{' '}
				- {transactionRoles[u.role as TransactionRole]?.name}
				{u.relationship ? ` (${transactionUserRelationship[u.relationship]?.shortName})` : ''}
			</li>
		))}
	</ul>
);

const formatPrice = (price: number | string | null) => price && extractBidValue(price);

export const getFormattedFormData = (form: ITransactionSummary) => ({
	...form,
	general: {
		...form.general,
		...(form.general.askOffer && {
			askOffer: { ...form.general.askOffer, price: formatBidValue(form.general.askOffer.price) },
		}),
	},
});

export const updateTransactionSummary = async (
	data: ITransactionSummary,
	transaction: ITransactionOverview,
	form: UseFormReturn<ITransactionSummary>,
) => {
	const { dirtyFields } = form.formState;
	const { buySide, general, sellSide } = data;

	const transactionUpdateFields: Partial<Transaction> = {};

	const transactionRef = getTransactionRef(transaction.id);

	const isSellSideIncomplete = transaction.hasSellSideSolicitor && !sellSide.legalFee2.withVat;
	const isBuySideIncomplete = transaction.hasBuySideSolicitor && !buySide.legalFee2.withVat;
	const isLegalFee2Incomplete = isSellSideIncomplete || isBuySideIncomplete;

	if (dirtyFields.general?.legalsStatus && general.legalsStatus === 'completed' && isLegalFee2Incomplete) {
		throw new Error(
			`${
				transaction.hasSellSideSolicitor && transaction.hasBuySideSolicitor
					? 'Both sell side and buy side'
					: transaction.hasSellSideSolicitor
					? 'Sell side'
					: 'Buy side'
			} Legal II field must be filled in order to put exchange step to completed.`,
		);
	}

	if (dirtyFields.general?.notes) {
		transactionUpdateFields.notes = general.notes;
	}
	if (dirtyFields.general?.titleNotes) {
		transactionUpdateFields.titleNotes = general.titleNotes;
	}

	if (dirtyFields.sellSide?.quote) {
		transactionUpdateFields['fees.sell.quote'] = {
			withoutVat: formatPrice(sellSide.quote.withoutVat),
			withVat: formatPrice(sellSide.quote.withVat),
		};
	}
	if (dirtyFields.sellSide?.searches) {
		transactionUpdateFields['fees.sell.searches'] = {
			withoutVat: formatPrice(sellSide.searches.withoutVat),
			withVat: formatPrice(sellSide.searches.withVat),
		};
	}
	if (dirtyFields.sellSide?.agencyRate) {
		transactionUpdateFields['fees.sell.agencyRate'] = {
			withoutVat: formatPrice(sellSide.agencyRate.withoutVat),
			withVat: formatPrice(sellSide.agencyRate.withVat),
		};
	}
	if (dirtyFields.sellSide?.agencyFee) {
		transactionUpdateFields['fees.sell.agencyFee'] = {
			withoutVat: formatPrice(sellSide.agencyFee.withoutVat),
			withVat: formatPrice(sellSide.agencyFee.withVat),
		};
	}
	if (dirtyFields.sellSide?.referralFee) {
		transactionUpdateFields['fees.sell.referralFee'] = {
			withoutVat: formatPrice(sellSide.referralFee.withoutVat),
			withVat: formatPrice(sellSide.referralFee.withVat),
		};
	}
	if (dirtyFields.sellSide?.legalFee) {
		transactionUpdateFields['fees.sell.legalFee'] = {
			withoutVat: formatPrice(sellSide.legalFee.withoutVat),
			withVat: formatPrice(sellSide.legalFee.withVat),
		};
	}
	if (dirtyFields.sellSide?.legalFee2) {
		transactionUpdateFields['fees.sell.legalFee2'] = {
			withoutVat: formatPrice(sellSide.legalFee2.withoutVat),
			withVat: formatPrice(sellSide.legalFee2.withVat),
		};
	}
	if (dirtyFields.sellSide?.officialCopies) {
		transactionUpdateFields['fees.sell.officialCopies'] = {
			withoutVat: formatPrice(sellSide.officialCopies.withoutVat),
			withVat: formatPrice(sellSide.officialCopies.withVat),
		};
	}
	if (dirtyFields.sellSide?.identityChecks) {
		transactionUpdateFields['fees.sell.identityChecks'] = {
			withoutVat: formatPrice(sellSide.identityChecks.withoutVat),
			withVat: formatPrice(sellSide.identityChecks.withVat),
		};
	}
	if (dirtyFields.sellSide?.searchesWSP) {
		transactionUpdateFields['fees.sell.searchesWSP'] = {
			withoutVat: formatPrice(sellSide.searchesWSP.withoutVat),
			withVat: formatPrice(sellSide.searchesWSP.withVat),
		};
	}

	if (dirtyFields.buySide?.quote) {
		transactionUpdateFields['fees.buy.quote'] = {
			withoutVat: formatPrice(buySide.quote.withoutVat),
			withVat: formatPrice(buySide.quote.withVat),
		};
	}
	if (dirtyFields.buySide?.searches) {
		transactionUpdateFields['fees.buy.searches'] = {
			withoutVat: formatPrice(buySide.searches.withoutVat),
			withVat: formatPrice(buySide.searches.withVat),
		};
	}
	if (dirtyFields.buySide?.agencyRate) {
		transactionUpdateFields['fees.buy.agencyRate'] = {
			withoutVat: formatPrice(buySide.agencyRate.withoutVat),
			withVat: formatPrice(buySide.agencyRate.withVat),
		};
	}
	if (dirtyFields.buySide?.agencyFee) {
		transactionUpdateFields['fees.buy.agencyFee'] = {
			withoutVat: formatPrice(buySide.agencyFee.withoutVat),
			withVat: formatPrice(buySide.agencyFee.withVat),
		};
	}
	if (dirtyFields.buySide?.referralFee) {
		transactionUpdateFields['fees.buy.referralFee'] = {
			withoutVat: formatPrice(buySide.referralFee.withoutVat),
			withVat: formatPrice(buySide.referralFee.withVat),
		};
	}
	if (dirtyFields.buySide?.legalFee) {
		transactionUpdateFields['fees.buy.legalFee'] = {
			withoutVat: formatPrice(buySide.legalFee.withoutVat),
			withVat: formatPrice(buySide.legalFee.withVat),
		};
	}
	if (dirtyFields.buySide?.legalFee2) {
		transactionUpdateFields['fees.buy.legalFee2'] = {
			withoutVat: formatPrice(buySide.legalFee2.withoutVat),
			withVat: formatPrice(buySide.legalFee2.withVat),
		};
	}
	if (dirtyFields.buySide?.officialCopies) {
		transactionUpdateFields['fees.buy.officialCopies'] = {
			withoutVat: formatPrice(buySide.officialCopies.withoutVat),
			withVat: formatPrice(buySide.officialCopies.withVat),
		};
	}
	if (dirtyFields.buySide?.identityChecks) {
		transactionUpdateFields['fees.buy.identityChecks'] = {
			withoutVat: formatPrice(buySide.identityChecks.withoutVat),
			withVat: formatPrice(buySide.identityChecks.withVat),
		};
	}
	if (dirtyFields.buySide?.searchesWSP) {
		transactionUpdateFields['fees.buy.searchesWSP'] = {
			withoutVat: formatPrice(buySide.searchesWSP.withoutVat),
			withVat: formatPrice(buySide.searchesWSP.withVat),
		};
	}

	if (dirtyFields.buySide?.margin) {
		transactionUpdateFields['fees.buy.margin'] = {
			withoutVat: formatPrice(buySide.margin.withoutVat),
			withVat: formatPrice(buySide.margin.withVat),
		};
	}
	if (dirtyFields.sellSide?.margin) {
		transactionUpdateFields['fees.sell.margin'] = {
			withoutVat: formatPrice(sellSide.margin.withoutVat),
			withVat: formatPrice(sellSide.margin.withVat),
		};
	}

	await updateDoc<unknown>(transactionRef, transactionUpdateFields);

	return { updated: true };
};

export const checkAreAllTitleDocumentsSelected = (titleDocuments: FormTitleDocumentCategory) => {
	const documents = propertyTitleDocumentsCategoriesKeys.reduce<FormTitleDocumentItem[]>((acc, category) => {
		acc.push(...titleDocuments[category]);

		return acc;
	}, []);

	return documents.every((d) => d.selected || d.availability_code !== propertyDocumentAvailabilityCodes.immediate);
};

export const updateNestedTitleDocumentsSelection = (
	titleDocuments: FormTitleDocumentCategory,
	selected: boolean,
): FormTitleDocumentCategory => {
	const updatedTitleDocs = propertyTitleDocumentsCategoriesKeys.reduce((acc, category) => {
		acc[category] = titleDocuments[category].map((document) => ({ ...document, selected }));

		return acc;
	}, {});

	return { ...titleDocuments, ...updatedTitleDocs };
};
