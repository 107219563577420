import { FC, useMemo } from 'react';
import { convertTrackerStepStatusToText } from 'src/utils/tracker';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import { TrackerStepStatus } from 'src/types';
import InProgressIcon from 'src/assets/in-progress';
import PendingIcon from 'src/assets/pending';
import { iconByTrackerStepStatus, labelByTrackerStage } from 'src/components/TransactionSummary/constants';
import {
	makeTooltipStyles,
	TrackerStepETA,
	TrackerStepIcon,
	TrackerStepStage,
	TrackerStepStages,
	TrackerStepTitle,
	TrackerStepWrapper,
} from './styles';
import { ITrackerStep } from './types';

const InProgress = <InProgressIcon color='#4d88f0' />;
const Pending = <PendingIcon color='#fac72d' width={65} />;
const Completed = <TrackerStepIcon icon='ep:success-filled' color='#1aba22' />;
const Error = <TrackerStepIcon icon='mdi:error-outline' color='#f0292c' />;

const TrackerStep: FC<ITrackerStep> = ({ title, status, order, date, stages }) => {
	const statusBasedOnEta: TrackerStepStatus = useMemo(() => {
		if (status === 'inProgress' && date) {
			const days = moment().diff(new Date(date), 'd');

			if (days >= 1) return 'pending';
		}

		return status;
	}, [status, date]);

	const content = useMemo(() => {
		switch (statusBasedOnEta) {
			case 'inProgress':
				return InProgress;
			case 'pending':
				return Pending;
			case 'completed':
				return Completed;
			case 'error':
				return Error;
			default:
				return order;
		}
	}, [statusBasedOnEta, order, date]);

	const isCompleted = statusBasedOnEta === 'completed' && !!date;
	const tooltipTitle = convertTrackerStepStatusToText(statusBasedOnEta);
	const tooltip = makeTooltipStyles();

	return (
		<Tooltip title={tooltipTitle} arrow placement='left' classes={tooltip}>
			<TrackerStepWrapper>
				{!!date && <TrackerStepETA isCompleted={isCompleted}>{moment(new Date(date)).format('DD MMM')}</TrackerStepETA>}
				{content}
				<TrackerStepTitle>{title}</TrackerStepTitle>
				<TrackerStepStages>
					{Object.keys(stages).map((s) => {
						const stage = stages[s];

						return (
							<TrackerStepStage key={s}>
								{iconByTrackerStepStatus[stage.status]} {labelByTrackerStage[s]}
							</TrackerStepStage>
						);
					})}
				</TrackerStepStages>
			</TrackerStepWrapper>
		</Tooltip>
	);
};

export default TrackerStep;
