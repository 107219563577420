import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { TrackerWrapper } from './styles';
import { ITrackerStep } from '../TrackerStep/types';
import TrackerStep from '../TrackerStep';
import { TrackerStepSeparator } from '../TrackerStep/styles';

const Tracker = () => {
	const transaction = useSelector(selectTransactionOverview);

	const steps: Array<Omit<ITrackerStep, 'order'> & { key: string }> = useMemo(
		() => [
			{ ...transaction.tracker.seller, key: 'seller', title: 'Seller' },
			{ ...transaction.tracker.property, key: 'property', title: 'Property' },
			{ ...transaction.tracker.buyer, key: 'buyer', title: 'Buyer' },
			{ ...transaction.tracker.legals, key: 'legals', title: 'Legals' },
		],
		// .sort((a, b) => {
		// 	if (a.key === 'legals') return 1;
		// 	if (b.key === 'legals') return -1;

		// 	if (a.status === 'completed' && !a.date) return b.status === 'completed' && !b.date ? 0 : -1;
		// 	if (b.status === 'completed' && !b.date) return 1;

		// 	if (!a.date) return 1;
		// 	if (!b.date) return -1;

		// 	return new Date(a.date).getTime() - new Date(b.date).getTime();
		// })
		[transaction.tracker],
	);

	return (
		<Stack flexDirection='row' m='40px 0 70px 20px'>
			<TrackerWrapper>
				{steps.map((step, index) => (
					<>
						<TrackerStep {...step} order={index + 1} />
						{index + 1 !== steps.length && <TrackerStepSeparator />}
					</>
				))}
			</TrackerWrapper>
		</Stack>
	);
};

export default Tracker;
